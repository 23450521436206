import { Box } from '../Box/Box'
import { DRAWER_PX_PADDING } from './MobileDrawer'
import { ForwardRefExoticComponent, forwardRef } from 'react'

export const MobileDrawerFooter: ForwardRefExoticComponent<{ children?: React.ReactNode }> =
  forwardRef(
    ({ children }, ref): JSX.Element => (
      <Box
        ref={ref}
        sx={{
          py: 6,
          px: DRAWER_PX_PADDING,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 4
        }}
        data-testid="mobile-drawer-footer"
      >
        {children}
      </Box>
    )
  )

MobileDrawerFooter.displayName = 'MobileDrawerFooter'
