import {
  Button,
  ResponsiveDialog,
  ResponsiveDialogActions,
  ResponsiveDialogContent,
  ResponsiveDialogTitle,
  Stack,
  Typography
} from '@dtx-company/design-system/src'
import { FC } from 'react'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import { useContactUsLockModal } from '@app/common/src/hooks/useContactUsLockModal/useContactUsLockModal'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

type ContactUsLockModalProps = {
  modalOpen: boolean
}
const imageSrc: Record<PlanType, string> = {
  [PlanType.FREE]: '/graphics/lockModal/growth-modal.png',
  [PlanType.PRO]: '/graphics/lockModal/growth-modal.png',
  [PlanType.PILOT]: '/graphics/lockModal/growth-modal.png',
  [PlanType.PRO_PLUS_ORG]: '/graphics/lockModal/growth-modal.png',
  [PlanType.PRO_FLEX]: '/graphics/lockModal/enterprise-modal.png',
  [PlanType.ENTERPRISE]: '/graphics/lockModal/enterprise-modal.png'
}

export const UPGRADE_LINK =
  'https://howto.flowcode.com/meetings/chelsea-flaum/flowcode-upcoming-renewal'

export const ContactUsLockModal: FC<ContactUsLockModalProps> = ({ modalOpen }) => {
  const { t } = useTranslation('lock-modal')
  const { codeLimit, pageLimit } = useAuthState()
  const { onClose, contactUsLockModalVariant } = useContactUsLockModal()
  const billingPlanType = useBillingPlanType()
  const planType = billingPlanType || PlanType.PRO_PLUS_ORG
  return (
    <ResponsiveDialog
      open={modalOpen}
      onClose={onClose}
      maxWidth={'sm'}
      showCloseIcon
      data-testid={'contact-us-dialog'}
    >
      <ResponsiveDialogTitle
        title={t(
          `${contactUsLockModalVariant}.title`,
          {},
          { default: 'Congrats on all those codes!' }
        )}
      />
      <ResponsiveDialogContent>
        <Stack gap={8} sx={{ '& img': { width: '100%', height: 'auto', alignSelf: 'center' } }}>
          <Typography variant={'subtitleSmall'}>
            {t(
              `${contactUsLockModalVariant}.description`,
              { codeLimit, pageLimit },
              {
                default:
                  "You've reached the limit for Flowcodes. To get more and access to next-level features, contact sales."
              }
            )}
          </Typography>
          <Image src={imageSrc[planType]} alt={'Unlock more codes'} width={550} height={250} />
        </Stack>
      </ResponsiveDialogContent>
      <ResponsiveDialogActions>
        <Stack direction={'row'} gap={2}>
          <Button variant={'text'} size={'large'} onClick={onClose}>
            {t(`${contactUsLockModalVariant}.cancelButton`, {
              default: 'Cancel'
            })}
          </Button>
          <Button
            size={'large'}
            href={UPGRADE_LINK}
            target={'_blank'}
            onClick={() => {
              fireAnalyticsEvent(`ContactUsLockModal_lock_modal_contact_sale`, {
                billingType: billingPlanType
              })
            }}
          >
            {t(`${contactUsLockModalVariant}.submitButton`, {
              default: 'Contact Sales'
            })}
          </Button>
        </Stack>
      </ResponsiveDialogActions>
    </ResponsiveDialog>
  )
}
