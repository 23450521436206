import { DRAWER_PX_PADDING } from './MobileDrawer'
import { FC } from 'react'
import { Stack } from '../Stack/Stack'
import { Typography } from '../Typography/Typography'
import { useMobileDrawerContext } from './MobileDrawerContext'

export interface MobileDrawerHeaderProps {
  title: string
  description?: string
}

export const MobileDrawerHeader: FC<MobileDrawerHeaderProps> = ({ title, description }) => {
  const { titleId, descriptionId, descriptionRef } = useMobileDrawerContext()

  return (
    <Stack gap={2} sx={{ mb: 4, px: DRAWER_PX_PADDING }} data-testid="mobile-drawer-header">
      <Stack gap={1} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography id={titleId} variant="subtitleMedium">
          {title}
        </Typography>
      </Stack>
      {description && (
        <Typography ref={descriptionRef} id={descriptionId} variant="bodyMedium">
          {description}
        </Typography>
      )}
    </Stack>
  )
}
