import { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer'
import { RefObject, createContext, useContext } from 'react'

export const MobileDrawerContext = createContext<MobileDrawerContext>({
  titleId: '',
  descriptionId: '',
  descriptionRef: undefined,
  onClose: undefined
})

export interface MobileDrawerContext {
  titleId: string
  descriptionId: string
  descriptionRef?: RefObject<HTMLElement>
  onClose: MuiDrawerProps['onClose']
}

export const useMobileDrawerContext = (): MobileDrawerContext => {
  const context = useContext(MobileDrawerContext)

  if (!context) {
    throw new Error(
      'Child components of MobileDrawer cannot be rendered outside the MobileDrawer component!'
    )
  }

  return context
}
