import { ForwardRefExoticComponent, forwardRef } from 'react'
import { variants } from '../Typography/Typography.overrides'
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle'

/**
 *
 * Documentation: [https://mui.com/material-ui/api/dialog-title]
 */

export type DialogTitleProps = Pick<MuiDialogTitleProps, 'id' | 'key' | 'children' | 'ref'>

export const DialogTitle: ForwardRefExoticComponent<DialogTitleProps> = forwardRef(
  // Text styles inserted here so that they affect all instances imported from `design-system`,
  // but not those imported directly from `@mui`
  (props, ref): JSX.Element => <MuiDialogTitle {...variants.headlineSmall} {...props} ref={ref} />
)

DialogTitle.displayName = 'DialogTitle'
