import { DialogActions, DialogActionsProps } from '../Dialog/DialogActions'
import { ForwardRefExoticComponent, forwardRef } from 'react'
import { MobileDrawerFooter } from '../MobileDrawer/MobileDrawerFooter'
import { Theme, useMediaQuery } from '@mui/material'

/**
 * The `ResponsiveDialogActions` is a wrapper component that renders a `MobileDrawerFooter` if screen size is less than `theme.breakpoints.md`, else a `DialogActions`.
 *
 * To be used as a child of `ResponsiveDialog`.
 *
 *
 * @see DialogActions
 * @see MobileDrawerFooter
 */
export const ResponsiveDialogActions: ForwardRefExoticComponent<DialogActionsProps> = forwardRef(
  (props, ref): JSX.Element => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const DialogActionsComponent = isMobile ? MobileDrawerFooter : DialogActions
    return <DialogActionsComponent {...props} ref={ref} />
  }
)

ResponsiveDialogActions.displayName = 'ResponsiveDialogActions'
