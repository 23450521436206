import { ForwardRefExoticComponent, forwardRef } from 'react'
import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps
} from '@mui/material/DialogContent'

export type DialogContentProps = Pick<MuiDialogContentProps, 'children' | 'id' | 'dividers' | 'ref'>

/**
 *
 * Documentation: [https://mui.com/material-ui/api/dialog-content]
 */

export const DialogContent: ForwardRefExoticComponent<DialogContentProps> = forwardRef(
  (props, ref): JSX.Element => <MuiDialogContent {...props} ref={ref} />
)

DialogContent.displayName = 'DialogContent'
