import { DialogClose } from '../Dialog/DialogClose'
import { DialogProps } from '../Dialog/Dialog'
import { FC, useId, useRef } from 'react'
import { MobileDrawerContext } from './MobileDrawerContext'
import { default as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material/Drawer'
import { StandardCSSProperties } from '@mui/system'

export type MobileDrawerProps = Pick<
  MuiDrawerProps,
  'children' | 'onClose' | 'open' | 'PaperProps'
> &
  Pick<DialogProps, 'fullScreen' | 'showCloseIcon'>

// applies to direct children of MobileDrawer to ensure consistent padding & handle scroll overflow
export const DRAWER_PX_PADDING: StandardCSSProperties['paddingLeft'] = 5

/**
 * A mobile drawer is a full-screen drawer that slides up from the
 * bottom of the screen. Use this for mobile devices only.
 *
 * @example
 * <MobileDrawer open={true} onClose={handleClose}>
 *  <MobileDrawerHeader title="Mobile Drawer Title" description="Mobile drawer description" />
 *   <MobileDrawerBody>
 *    <Typography>Mobile drawer body</Typography>
 *   </MobileDrawerBody>
 *   <MobileDrawerFooter>
 *     <Button fullWidth variant="text" onClick={handleClose}>
 *       Cancel
 *     </Button>
 *     <Button fullWidth onClick={handleClose}>
 *       Save
 *     </Button>
 *   </MobileDrawerFooter>
 * </MobileDrawer>
 */
export const MobileDrawer: FC<MobileDrawerProps> = ({
  children,
  fullScreen,
  onClose,
  open,
  PaperProps,
  showCloseIcon
}) => {
  const id = useId()
  const titleId = `${id}-title`
  const descriptionId = `${id}-description`
  const descriptionRef = useRef<HTMLElement>(null)
  const topPadding = showCloseIcon ? 11 : 6

  return (
    <MobileDrawerContext.Provider value={{ titleId, descriptionId, descriptionRef, onClose }}>
      <MuiDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        data-testid="mobile-drawer"
        ModalProps={{
          role: 'dialog',
          'aria-labelledby': titleId,
          ...(descriptionRef.current && { 'aria-describedby': descriptionId })
        }}
        PaperProps={{
          elevation: 3,
          ...PaperProps,
          sx: {
            pt: topPadding,
            height: fullScreen ? '100%' : undefined,
            overflow: 'visible',
            ...(PaperProps && PaperProps.sx && PaperProps.sx)
          }
        }}
      >
        {showCloseIcon && <DialogClose onClose={onClose} />}
        {children}
      </MuiDrawer>
    </MobileDrawerContext.Provider>
  )
}
