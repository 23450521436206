import { DialogTitle, DialogTitleProps } from '../Dialog/DialogTitle'
import { ForwardRefExoticComponent, forwardRef } from 'react'
import { MobileDrawerHeader, MobileDrawerHeaderProps } from '../MobileDrawer/MobileDrawerHeader'
import { Theme, useMediaQuery } from '@mui/material'

type ResponsiveDialogTitleProps = Omit<DialogTitleProps, 'children'> &
  Omit<MobileDrawerHeaderProps, 'description' | 'showCloseIcon'> & {
    /**
     * @remarks
     *
     * Only applicable for mobile layout
     */
    description?: MobileDrawerHeaderProps['description']
  }
/**
 * The `ResponsiveDialogTitle` is a wrapper component that renders a `MobileDrawerHeader` if screen size is less than `theme.breakpoints.md`, else a `DialogTitle`.
 * To be used as a child of `ResponsiveDialog`.
 *
 * @see DialogTitle
 * @see MobileDrawerHeader
 */
export const ResponsiveDialogTitle: ForwardRefExoticComponent<ResponsiveDialogTitleProps> =
  forwardRef((props, ref): JSX.Element => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const { title, ...rest } = props
    return isMobile ? (
      <MobileDrawerHeader {...props} />
    ) : (
      <DialogTitle {...rest} ref={ref}>
        {title}
      </DialogTitle>
    )
  })

ResponsiveDialogTitle.displayName = 'ResponsiveDialogTitle'
