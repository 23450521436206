import { Box, BoxProps } from '../Box/Box'
import { DRAWER_PX_PADDING } from './MobileDrawer'
import { forwardRef } from 'react'

export const MobileDrawerBody = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, sx, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{ flex: '1', overflowY: 'auto', px: DRAWER_PX_PADDING, ...sx }}
        {...rest}
        data-testid="mobile-drawer-body"
      >
        {children}
      </Box>
    )
  }
)
MobileDrawerBody.displayName = 'MobileDrawerBody'
