import { AuthenticationFormProps } from '../AuthenticationForm.types'
import { Box, Button, Skeleton, Stack } from '@dtx-company/design-system/src'
import { FC, useCallback } from 'react'
import { ONBOARDING_FEATURES } from '@dtx-company/true-common/src/constants/featureFlags'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SocialProvider } from '@dtx-company/true-common/src/types/socialProvider'
import {
  auth_signin_form_facebook_started,
  auth_signin_form_google_started,
  auth_signup_form_facebook_started,
  auth_signup_form_google_started
} from '@dtx-company/inter-app/src/constants/authHeapEvents'
import { fireAnalyticsEvent } from '../../../event-tracking/helpers/fireAnalyticsEvent'
import {
  getAskOnboardingQuestions,
  setAskOnboardingQuestions
} from '@dtx-company/app/components/OnboardingQuestions/utils/onboardingQuestionsStore'
import { useAuthCache } from '@app/authentication/AuthCache/hooks'
import { useAuthFormContext } from '../AuthenticationForm'
import { useBuildLoggedOutGeneratorCreateCodePayload } from '@app/code/src/components/LoggedOutCodeGeneratorV2/hooks/useLoggedOutGeneratorCreateCode.hooks'
import {
  useFacebookLoginUrl,
  useGoogleLoginUrl
} from '@dtx-company/app/shared/common/src/hooks/useSocialLoginUrl'
import { useFlowFeature } from '../../../hooks/useFlowFeature/useFlowFeature'
import { useRouter } from 'next/router'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

export const SocialLoginButtons: FC<
  {
    checkTerms: (args: { quiet?: boolean } | void) => boolean
    fireHeapEventOnClick?: (provider: SocialProvider) => void
    validateOnFormFocus?: () => boolean
  } & Pick<AuthenticationFormProps, 'onSubmit'>
> = ({ checkTerms, fireHeapEventOnClick, validateOnFormFocus }) => {
  const isSignUp = useAuthFormContext().action === 'sign up'
  const { t } = useTranslation('authentication')
  const router = useRouter()
  const isFreeQrGeneratorPage = router.pathname === Routes.FREE_QR_CODE_GENERATOR
  const locgFormValues = useBuildLoggedOutGeneratorCreateCodePayload()
  const queryParams = router.query
  const authCache = useAuthCache()
  const [onboardingQuestions] = useFlowFeature(ONBOARDING_FEATURES, { autoUpdate: true })

  const trackSignupButtonClicked = useCallback((provider: SocialProvider) => {
    fireAnalyticsEvent('Revenue_SignUpPage_Clicked_SignUpButton', {
      signupButtonType: provider.toUpperCase()
    })
    fireAnalyticsEvent('userClickedAuthFormAuthenticateWithSocial', {
      socialProvider: provider
    })
  }, [])

  const { url: googleLoginUrl } = useGoogleLoginUrl(isSignUp ? 'signup' : 'login')
  const { url: facebookLoginUrl } = useFacebookLoginUrl('login')

  const handleAuthClick = async (provider: SocialProvider): Promise<void> => {
    trackSignupButtonClicked(provider)
    //set up askOnboardingQuestions to true if it is allowed.
    // If user will be redirected to frontegg-sso-redirect page, the flag will be switched to false becase all sso users are enterprise
    //If user will be redirected to frontegg-social-redirect page redirect to onboarding question will be done
    const askOnboardingQuestionsFlag = getAskOnboardingQuestions()
    const askOnboardingQuestions =
      isSignUp && onboardingQuestions && askOnboardingQuestionsFlag !== false

    fireAnalyticsEvent('socialRedirect_onboardingQuestions_feature_flag', {
      onboardingQuestionsFeatureFlag: onboardingQuestions,
      isSignIn: !isSignUp,
      getAskOnboardingQuestions: askOnboardingQuestionsFlag
    })
    if (askOnboardingQuestions) {
      setAskOnboardingQuestions(true)
    }
    if (!checkTerms()) return
    if (validateOnFormFocus ? !validateOnFormFocus() : false) {
      return
    }
    fireHeapEventOnClick?.(provider)
    isFreeQrGeneratorPage
      ? await Promise.all([
          authCache?.queryParams.set(queryParams),
          authCache?.locgFormValues.set(locgFormValues)
        ])
      : await authCache?.queryParams.set(queryParams)

    switch (provider) {
      case SocialProvider.GOOGLE:
        window.location.href = googleLoginUrl
        break
      case SocialProvider.FACEBOOK:
        window.location.href = facebookLoginUrl
        break
    }
  }

  const disabled = !checkTerms({ quiet: true })

  return (
    <Stack
      sx={{
        gap: 4,
        '& .MuiBox-root': {
          display: 'flex',
          alignItems: 'center'
        }
      }}
    >
      {googleLoginUrl ? (
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => {
            handleAuthClick(SocialProvider.GOOGLE)
            if (!disabled) {
              fireAnalyticsEvent(
                isSignUp ? auth_signup_form_google_started : auth_signin_form_google_started
              )
            }
          }}
        >
          <Box sx={{ pr: 6 }}>
            <Image
              src={'/icons/social-media/google-color.svg'}
              alt=""
              role="presentation"
              width={18}
              height={18}
            />
          </Box>
          {t('SocialsLoginButton.label', {
            action: isSignUp ? 'Sign up' : 'Sign in',
            social: 'Google'
          })}
        </Button>
      ) : (
        <Skeleton variant="rectangular" height={50} />
      )}

      {!isFreeQrGeneratorPage && (
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => {
            handleAuthClick(SocialProvider.FACEBOOK)
            if (!disabled) {
              fireAnalyticsEvent(
                isSignUp ? auth_signup_form_facebook_started : auth_signin_form_facebook_started
              )
            }
          }}
        >
          <Box sx={{ pr: 6 }}>
            <Image
              src={'/icons/social-media/facebook-color.svg'}
              alt=""
              role="presentation"
              width={18}
              height={18}
            />
          </Box>
          {t('SocialsLoginButton.label', {
            action: isSignUp ? 'Sign up' : 'Sign in',
            social: 'Facebook'
          })}
        </Button>
      )}
    </Stack>
  )
}
