import { Dialog, DialogProps } from '../Dialog/Dialog'
import { ForwardRefExoticComponent, forwardRef } from 'react'
import { MobileDrawer } from '../MobileDrawer/MobileDrawer'
import { Theme, useMediaQuery } from '@mui/material'

/**
 * The `ResponsiveDialog` is a wrapper component that renders a `Drawer` if screen size is less than `theme.breakpoints.md`, else a `Dialog`.
 *
 *
 * ### Documentation
 * Dialog - [https://mui.com/material-ui/api/dialog]
 *
 * MobileDrawer - [https://mui.com/material-ui/api/drawer]
 * @see Dialog
 * @see MobileDrawer
 */
export const ResponsiveDialog: ForwardRefExoticComponent<DialogProps> = forwardRef(
  (props, ref): JSX.Element => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const DialogComponent = isMobile ? MobileDrawer : Dialog
    return <DialogComponent {...props} ref={ref} />
  }
)

ResponsiveDialog.displayName = 'ResponsiveDialog'
