import { BoxProps } from '../Box/Box'
import { DialogContent } from '../Dialog/DialogContent'
import { MobileDrawerBody } from '../MobileDrawer/MobileDrawerBody'
import { Theme, useMediaQuery } from '@mui/material'
import { forwardRef } from 'react'

/**
 * The `ResponsiveDialogContent` is a wrapper component that renders a `MobileDrawerBody` if screen size is less than `theme.breakpoints.md`, else a `DialogContent`.
 * To be used as a child of `ResponsiveDialog`.
 *
 *
 * @see DialogContent
 * @see MobileDrawerBody
 */
export const ResponsiveDialogContent = forwardRef<HTMLDivElement, BoxProps>(
  (props, ref): JSX.Element => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const DialogContentComponent = isMobile ? MobileDrawerBody : DialogContent

    return <DialogContentComponent {...props} ref={ref} />
  }
)

ResponsiveDialogContent.displayName = 'ResponsiveDialogContent'
